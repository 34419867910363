<template>
    <div class="background p-8">
        <div class="mb-4">
            <label class="block">
                Имя
            </label>
            <input class="w-full p-2 mt-1 border-0 rounded-md" type="text" v-model="form.firstName">
        </div>
        <div class="mb-4">
            <label class="block">
                Фамилия
            </label>
            <input class="w-full p-2 mt-1 border-0 rounded-md" type="text" v-model="form.lastName">
        </div>
        <div class="mb-4">
            <label class="block">
                Чем занимаетесь
            </label>
            <textarea class="w-full p-2 mt-1 rounded-md" v-model="form.business"/>
        </div>
        <div class="mb-4">
            <label class="block">
                Как мы можем с Вами связаться
            </label>
            <textarea class="w-full p-2 mt-1 rounded-md" v-model="form.contacts"/>
        </div>

        <div class="text-center">
            <button class="rounded-xl px-4 py-2 tg-button" @click="send">Отправить анкету</button>
        </div>
    </div>
</template>

<script>

import '@/assets/tailwind.css';

export default {
    name: 'App',
    components: {},

    data: () => ({
        form: {
            firstName: '',
            lastName: '',
            contacts: '',
            business: '',
        },
    }),

    methods: {
        send() {
            window.Telegram.WebApp.sendData(JSON.stringify(this.form));
            window.Telegram.WebApp.close();
        }
    }
}

</script>

<style lang="scss">
/*
:root {
    --tg-theme-bg-color: #17212b;
    --tg-theme-button-color: #5288c1;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-hint-color: #708499;
    --tg-theme-link-color: #6ab3f3;
    --tg-theme-secondary-bg-color: #232e3c;
    --tg-theme-text-color: #f5f5f5;
}

:root {
    --tg-theme-bg-color: #ffffff;
    --tg-theme-button-color: #40a7e3;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-hint-color: #999999;
    --tg-theme-link-color: #168acd;
    --tg-theme-secondary-bg-color: #f1f1f1;
    --tg-theme-text-color: #000000;
}
*/

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans.woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TinkoffSans';
    src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'haas';
    src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("assets/fonts/fa-regular-400.eot");
    src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
    font-family: 'Font Awesome';
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url("assets/fonts/fa-solid-900.eot");
    src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    color: var(--tg-theme-text-color);
    background-color: var(--tg-theme-bg-color);
    font-kerning: normal;
    text-rendering: optimizeLegibility;
}

.tg-button {
    color: var(--tg-theme-button-text-color);
    background-color: var(--tg-theme-button-color);
}

input, textarea {
    color: var(--tg-theme-text-color);
    background-color: var(--tg-theme-secondary-bg-color);

    &:active, &:focus-visible, &:focus-within, &:focus {
        border: 1px solid var(--tg-theme-button-color);
        outline: none !important;
    }
}

</style>
